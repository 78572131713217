import { createAsyncThunk } from '@reduxjs/toolkit';
import referenceApi from '../../services/referenceApi';

export const fetchAppInitialData = createAsyncThunk(
  'app/fetchAppInitialData',
  async () => await referenceApi.getMainReferences(),
);

export default {
  fetchAppInitialData,
};
