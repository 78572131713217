import React, {
  useEffect, useState,
} from 'react';

// Styles
import styles from '../../../Press/PressPage.module.less';

import landingApi from '../../../../../services/landingApi';
import Spinner from '../../../../../components/Spinner/Spinner';

const OldPressComponent = () => {
  const [publications, setPublications] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await landingApi.getPress();
        setPublications(response.data);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <section className={styles.Reviews}>
      <div className={styles.container1170}>
        <article className={styles.ReviewsCards}>
          <b className={styles.ReviewsTitle}>What they&apos;re saying...</b>
          <Spinner mini show={loading}/>
          {publications.length ? publications.map((publication) => (
            <div key={`${publication.title}_title`} className={styles.ReviewsCardWrapper}>
              <div className={styles.ReviewsCard}>
                <p className={styles.ReviewsCardDate}>
                  <span
                    className={styles.ReviewsCardDateName}
                  >
                    {publication.authorName}
                  </span>
                  ,&nbsp;
                  <span className={styles.ReviewsCardDateDate}>{publication.dateCreated}</span>
                </p>
                <h2 className={styles.ReviewsCardTitle}>{publication.title}</h2>
                <p className={styles.ReviewsCardText}>
                  {`"${publication.description}"`}
                </p>
                <a
                  href={publication.authorUrl}
                  className={`${styles.ReviewsCardLink} ${styles.linkPrimary}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Read&nbsp;more
                </a>
              </div>
              <div className={styles.ReviewsCardLogo}>
                <img
                  className={styles.ReviewsCardLogoImg}
                  src={publication.logo}
                  alt={`${publication.authorName} company's logo`}
                />
              </div>
            </div>
          )) : loading ? '' : 'No in press publications found...'}
        </article>
      </div>
    </section>
  );
};

export default OldPressComponent;
