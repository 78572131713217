import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../index';
import {
  MarkersWithOptimisationState,
  BloodMarkerNew,
  CategoriesWithOptimisationState,
} from '../../interfaces/Blood/BloodMarker';
import { MarkerValue } from '../../interfaces/Blood/MarkerValue';
import {
  STATE_OPTIMIZED,
  STATE_LOW,
  STATE_HIGH,
  STATE_BORDERLINE_HIGH, STATE_BORDERLINE_LOW, CANNOT_DETERMINE,
} from '../../Constants/BloodResultStatus';
import { bloodMarkersSelector, bloodMarkerCategoriesSelector } from '../reference/selectors';
import { MarkerSortingState } from '../../interfaces/Blood/MarkerSortingState';
import { CategoryResult, MarkerCategory } from '../../interfaces/Blood/MarkerCategory';
import { getStateByScore } from '../../helpers/getBloodStatus';

export const userSelector = ({ user }: RootState) => user.user;
export const addressSelector = ({ user }: RootState) => user.address;
export const profileSelector = ({ user }: RootState) => user.profile;
export const rosettaSelector = ({ user }: RootState) => user.rosetta;
export const userReferencesSelector = ({ user }: RootState) => user.references;
export const securitySelector = ({ user }: RootState) => user.security;
export const innerageResultsSelector = ({ user }: RootState) => user.innerageResults;
export const innerageBloodMarkersSelector = ({ user }: RootState) => user.innerageBloodMarkers;
export const innerageDailyActionsSelector = ({ user }: RootState) => user.innerageDailyActions;
export const bruSubscriptionSelector = ({ user }: RootState) => user.subscriptions.bloodResultUpload;
export const womanHealthSelector = ({ user }: RootState) => user.womanHealth;
export const bloodResultsSelector = ({ user }: RootState) => user.bloodResults;
export const bloodRangesSelector = ({ user }: RootState) => user.bloodRanges;
export const categoryResultsSelector = ({ user }: RootState) => user.categoryResults;
export const categoriesForResultsSelector = ({ user }: RootState) => user.categoriesForResults;
export const biomarkersSortingStateSelector = ({ user }: RootState) => user.biomarkersSortingState;
export const availableMarkersAbbrSelector = ({ recommendations }: RootState) => recommendations.availableMarkersAbbr;
export const showCompleteHealthIaPopupSelector = ({ user }: RootState) => user.showCompleteHealthIaPopup;

// TODO change name, use createSelector
export const bloodResultsWithMarkerAbbrSelector = (markerAbbr: string) => ({ user }: RootState) => (!user.bloodResults ? [] : user.bloodResults
  .filter((bloodResult) => bloodResult
    .markerValues.filter((makerValue) => makerValue.markerAbbr === markerAbbr).length > 0)
  .sort((resultOne, resultTwo) => (resultOne.testedAt > resultTwo.testedAt ? 1 : -1)));

export const notificationTagsSelector = ({ user }: RootState) => user.notificationTags;

export const showAIPopupSelector = ({ user }: RootState) => user.showAIPopup;

export const sortedByOptimisationBloodMarkersSelector = createSelector(
  bloodMarkersSelector,
  bloodResultsSelector,
  biomarkersSortingStateSelector,
  profileSelector,
  availableMarkersAbbrSelector,
  (bloodMarkers, bloodResults, sortingState, profile, availableMarkersAbbr): MarkersWithOptimisationState[] => {
    if (!bloodResults || !bloodMarkers || !availableMarkersAbbr) return null;

    const filteredMarkers = bloodMarkers
      .filter((item) => availableMarkersAbbr.includes(item.abbr))
      .filter((item) => item.forGender === profile.gender || item.forGender == "all");
    const azSorted = filteredMarkers.sort((a, b) => a.name.localeCompare(b.name));

    if (sortingState === MarkerSortingState.A_Z) {
      return [
        {
          stateName: 'all',
          markers: azSorted,
        },
      ];
    }

    const sorted = (() => {
      if (sortingState === MarkerSortingState.OPTIMIZED) {
        return {
          optimized: [],
          notOptimized: [],
          neverTested: [],
        } as Record<string, BloodMarkerNew[]>;
      }

      return {
        notOptimized: [],
        optimized: [],
        neverTested: [],
      } as Record<string, BloodMarkerNew[]>;
    })();

    const notOptimizedStates: Record<'yellow' | 'orange' | 'red', BloodMarkerNew[]> = {
      yellow: [],
      orange: [],
      red: [],
    };

    azSorted.forEach((marker) => {
      let resultMarkerValue: MarkerValue = null;
      bloodResults.some((result) => result.markerValues
        ?.some((markerValue) => {
          if (markerValue.markerAbbr === marker.abbr) {
            resultMarkerValue = markerValue;
            return true;
          }
          return false;
        }));
      if (!resultMarkerValue) {
        sorted.neverTested.push(marker);
      } else if (resultMarkerValue.optimizationStateValue === STATE_OPTIMIZED) {
        sorted.optimized.push(marker);
      } else if ([STATE_LOW, STATE_HIGH].includes(resultMarkerValue.optimizationStateValue)) {
        notOptimizedStates.red.push(marker);
      } else if ([STATE_BORDERLINE_HIGH, STATE_BORDERLINE_LOW].includes(resultMarkerValue.optimizationStateValue)) {
        notOptimizedStates.orange.push(marker);
      } else if (resultMarkerValue.optimizationStateValue === CANNOT_DETERMINE) {
        sorted.notOptimized.push(marker);
      } else {
        notOptimizedStates.yellow.push(marker);
      }
    });

    if (sortingState === MarkerSortingState.OPTIMIZED) {
      sorted.notOptimized = sorted.notOptimized.concat(notOptimizedStates.yellow, notOptimizedStates.orange, notOptimizedStates.red);
    } else {
      sorted.notOptimized = sorted.notOptimized.concat(notOptimizedStates.red, notOptimizedStates.orange, notOptimizedStates.yellow);
    }

    return Object.entries(sorted)
      .map(([stateName, markers]) => ({
        stateName, markers,
      }));
  });

export const lastAvailableCategoryResultsSelector = createSelector(
  bloodMarkerCategoriesSelector,
  categoriesForResultsSelector,
  (categories, categoriesForResults): CategoryResult[] => {
    if (!categories || !categoriesForResults) return [];

    const getCategoryResultByAbbr = (categoryAbbr: string) => {
      const resultsWithCalculatedCategory = categoriesForResults
        .filter((item) => item
          .categoryScores.find((categoryScore) => categoryScore.abbr === categoryAbbr)?.score !== null,
        );

      if (resultsWithCalculatedCategory.length > 0) {
        return resultsWithCalculatedCategory[resultsWithCalculatedCategory.length - 1]?.categoryScores.find((item) => item.abbr === categoryAbbr);
      }

      return categoriesForResults[categoriesForResults.length - 1]?.categoryScores.find((item) => item.abbr === categoryAbbr);
    };

    return categories.map((item) => getCategoryResultByAbbr(item.abbr)).filter((item) => item !== undefined);
  });

export const allResultsFetchedSelector = ({ user }: RootState) => user.allResultsFetched;

export const sortedByOptimisationCategoriesSelector = createSelector(
  bloodMarkerCategoriesSelector,
  biomarkersSortingStateSelector,
  profileSelector,
  lastAvailableCategoryResultsSelector,
  (categories, sortingState, profile, lastAvailableCategoryResults): CategoriesWithOptimisationState[] => {
    if (!categories || !lastAvailableCategoryResults) return null;

    const filteredCategories = categories.filter((item) => item.forGender === profile.gender || item.forGender == "all");
    const azSorted = filteredCategories.sort((a, b) => a.name.localeCompare(b.name));

    if (sortingState === MarkerSortingState.A_Z) {
      return [
        {
          stateName: 'all',
          categories: azSorted,
        },
      ];
    }

    const sorted = (() => {
      if (sortingState === MarkerSortingState.OPTIMIZED) {
        return {
          optimized: [],
          notOptimized: [],
          neverTested: [],
        } as Record<string, MarkerCategory[]>;
      }

      return {
        notOptimized: [],
        optimized: [],
        neverTested: [],
      } as Record<string, MarkerCategory[]>;
    })();

    const ascDescCoeff = sortingState === MarkerSortingState.OPTIMIZED ? -1 : 1;
    const scoreSorted = [...azSorted].sort((a, b) => {
      const resultA = lastAvailableCategoryResults.find((result) => result.abbr === a.abbr);
      const resultB = lastAvailableCategoryResults.find((result) => result.abbr === b.abbr);
      if (!resultB && !resultA) {
        return 0;
      } if (!resultA || !resultB) {
        return resultA ? 1 : -1;
      }
      return (resultA.score - resultB.score) * ascDescCoeff;
    });

    scoreSorted.forEach((category) => {
      const result = lastAvailableCategoryResults.find((result) => result.abbr === category.abbr);
      const state = getStateByScore(result?.score);

      if (!result || result?.score === null) {
        sorted.neverTested.push(category);
      } else if (state === 0) {
        sorted.optimized.push(category);
      } else {
        sorted.notOptimized.push(category);
      }
    });

    return Object.entries(sorted)
      .map(([stateName, categories]) => ({
        stateName, categories,
      }));
  });

export default {
  userSelector,
  addressSelector,
  profileSelector,
  rosettaSelector,
  userReferencesSelector,
  securitySelector,
  innerageResultsSelector,
  innerageBloodMarkersSelector,
  innerageDailyActionsSelector,
  bruSubscriptionSelector,
  notificationTagsSelector,
};
